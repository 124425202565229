/*
* 업무구분:  안내자료
* 화면 명:   나의보관함
* 화면 설명: 나의보관함  그룹명 등록 및 관리
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="안내자료 스크랩" :show-title="true" type="subpage" @on-header-left-click="fn_HistoryBack" :topButton="true">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="top-btn-area type2 con-btn">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_bs4_newGroup_open()">
          <mo-icon icon="msp-plus-round2"></mo-icon><span class="ml5">새 그룹</span>
        </mo-button> 
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container v-if="!lv_IsBkmrkDirLoading"  alignV="start" componentid="" direction="column" class="ns-radio-list2 mb82">  <!-- 퍼블추가 : mb82 하단 버튼영역만큼 -->
        <mo-list :list-data="lv_BkmrkDirVOList">
          <template #list-item="{item, index}">
            <mo-list-item>
              <ur-box-container alignV="center" componentid="" direction="row" class="w100">  <!-- 퍼블추가 -->
                <mo-radio v-model="radio" :value="String(index )" />
                <div class="list-list-item__contents__title flex-1"> 
                  <div class="list-item__contents__title mb0">  <!-- 퍼블추가 : mb0 -->
                    <span class="name txtSkip fwn"  @click="fn_OnSelectedBkmrkGroup(item.bkmrkGrpNm)">{{item.bkmrkGrpNm.replace(/\#\$SA\$\#/g, ' ')}}</span>
                  </div>
                </div>
              </ur-box-container>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- no-data 데이타 없음 --> 
      <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data no-group">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray"><p class="mt20">새 그룹을 생성해 주세요</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>  
      <!-- no-data end -->
      <!--   __prevent-back-close  -->
      <mo-bottom-sheet ref="bottomSheet_208M" alignV="start" componentid="" direction="column" class="ns-bottom-menu">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CheckDeleteBkmrkGroup()">삭제</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_bs3_modifyGroup_open()">편집</mo-button>
          </div>
        </ur-box-container>  
      </mo-bottom-sheet>
    </ur-box-container>

    <!-- 그룹 삭제: 스크랩자료가 있을 경우 -->
    <mo-bottom-sheet ref="nsbottomsheet1" class="ns-bottom-sheet msp-bottomSt-alert">
      <div class="customer-info-txt txt-center">
        <p class="ment">선택 그룹에 저장된 스크랩 자료가 있습니다.<br/>그룹을 삭제하시겠습니까?</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_bs1_deleteGroupDetail_close()" name="취소">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnConfirmDeleteBkmrkGroupWithContent()" name="삭제">삭제</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 그룹 삭제 -->
    <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet msp-bottomSt-alert">
      <div class="customer-info-txt txt-center">
        <p class="ment">선택된 그룹을 삭제하시겠습니까?</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_bs2_deleteGroup_close()" name="취소">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnConfirmDeleteBkmrkGroupNoneContent()" name="삭제">삭제</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    
    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="5000" />

    <!-- 그룹명 편집 -->
    <mo-bottom-sheet ref="nsbottomsheet3" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        그룹명 편집
        <div class="ns-btn-close" @click="fn_bs3_modifyGroup_close()" name="닫기"></div>
      </template>
       
      <div class="content-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="mt15">
            <div class="ns-certify-sed"><!-- error class 추가로 상태변환 -->
              <div class="ns-certify-sed-code row-text">
                <mo-text-field  class="form-input-name full" v-model="lv_NewGroupName"  ref="editGroupNmRef" @input="fn_setVoVal('lv_NewGroupName')" :rules="rule_NewGroupName"  underline clearable placeholder="그룹명 입력" />
              </div>
             <!--   <div class="ns-certify-sed-txt">
              <div class="txt-error" :disabled="bs_err_msg1">동일한 그룹명이 존재합니다.</div>
              <div class="txt-error" :disabled="bs_err_msg2">그룹명은 한글20자(영문 30자) 이상 입력할 수 없습니다.</div>
              </div>  -->
            </div>          
          </ur-box-container>
        </ur-box-container>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" class="ns-btn-round white" @click="fn_bs3_modifyGroup_close()">취소</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CheckExistedMySalesGroupName('U')" :class="['ns-btn-round blue', conFirmDisable ? 'mo-button--disabled' : '']">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 새 그룹 생성 -->
    <mo-bottom-sheet ref="nsbottomsheet4" :close-btn="false" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        새 그룹 생성
        <div class="ns-btn-close" @click="fn_bs4_newGroup_close()" name="닫기"></div>
      </template>
       
      <div class="content-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="mt15">
            <div class="ns-certify-sed"><!-- error class 추가로 상태변환 -->
              <div class="ns-certify-sed-code row-text">
                <mo-text-field  class="form-input-name full" ref="newGroupNmRef" v-model="lv_NewGroupName" @input="fn_setVoVal('lv_NewGroupName')" :rules="rule_NewGroupName"   underline clearable placeholder="그룹명 입력" />
              </div>
             <!-- <div class="ns-certify-sed-txt">
                <div class="txt-error" :disabled="bs_err_msg1">동일한 그룹명이 존재합니다.</div>
                <div class="txt-error" :disabled="bs_err_msg2">그룹명은 한글20자(영문 30자) 이상 입력할 수 없습니다.  </div>  
              </div>  --> 
            </div>         
          </ur-box-container>
        </ur-box-container>
      </div>

      <template v-slot:action>
       <!-- <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">  -->
        <ur-box-container align-v="start" componentid="ur_box_container_002" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">  
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" class="ns-btn-round white" @click="fn_bs4_newGroup_close()">취소</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_CheckExistedMySalesGroupName('I')" :class="['ns-btn-round blue', conFirmDisable ? 'mo-button--disabled' : '']">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
 import SaConstants from '@/config/constants/saConstants'
 import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
 import Msg from '@/systems/webkit/msg/msg'
 import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
 import moment from 'moment'
  // My 세일즈북 새그룹 생성
  import MSPSA209P from '@/ui/sa/MSPSA209P'
  import urTabsContainerVue from '~/src/_uidev/containers/tab/urTabsContainer.vue'
  

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA208M",
    screenId: "MSPSA208M",
    components: {
      MSPSA209P
    },

  props: {
  },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/  
  data () {
    return {
        userInfo: {}, // 사용자정보 obj
        lv_cnsltNo: '', // 컨설턴트 번호
        isMngr: false, // 지점장 여부

        conFirmDisable: true,  // 확인버튼  
         
        toastMsg: '', // 문자발송 toast 문구
        ProcessFlagCode: String,  // 그룹명 신규 / 변경 구분 
        pSelectedGroupName: String, // 그룹명 매개변수 

       popup209: {}, // MSPSA209P  새그룹추가    

      radio: '', // 라디오 변수
      lv_SelectedBkmrkGroup: '', // 선택한 대분류 

      canSave: false,
      // nextKey 방식의 페이징 관련 정보 저장
      // http.js에 정의된 변수를 overriding
      stndKeyList: [],
      // 한번에 호출할 컨텐츠 건수
      // http.js에 정의된 변수를 overriding
      pageRowCnt: 100,

      // 내가사용한 자료 목록 관련 변수
      lv_IsBkmrkDirLoading: false,
      // 내가사용한 자료 목록
      lv_BkmrkDirVOList: [],
      // 내가사용한 자료 전체 목록
      lv_BkmrkDirItems: [],
      // 서비스 조회 파라미터 (필요시 parameter 추가)
      // 더 이상 조회할 데이터가 있는지 여부
      lv_isBkmrkDirMoreData: true,
      // nextKey방식의 페이징 변수
      lv_BkmrkDirStndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],

      // 내가사용한 자료 목록 관련 변수
      lv_IsBkmrkGrpCntntLoading: false,
      // 내가사용한 자료 목록
      lv_BkmrkGrpCntntVOList: [],
      // 선택 그룹내 자료 전체 목록
      lv_BkmrkGrpCntntItems: [],

      lv_NewGroupName: '',  //  그룹명 입력변수
      lv_OldGroupName: '',  //  그룹명 입력변수
      lv_searchGroupName: '',  //  삭제그룹명 입력변수

      lv_newGrpNm: '', // 그룹명 검증 오류 텍스트 
      grpNmFlag: false, //
      existedFlag: false, //

      rule_NewGroupName:[
          v => !(this.rule_NewGroupName_chk1(this.grpNmFlag))   || '그룹명은 한글20자(영문 30자) 이상 입력할 수 없습니다.',
          v => !(this.rule_NewGroupName_chk2(this.existedFlag))   || '동일한 그룹명이 존재합니다.'      
        ],  
    }
  },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.radio = '0'

    // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

       // 나의보관함 그룹 조회
       this.fn_SelBkmrkDir()
    },

   /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    },

    mounted() {

       // 화면접속로그 등록(필수)
       this.$bizUtil.insSrnLog('MSPSA208M')
      // 스크롤
      this.scrollAnimate = createScrollAnimateInstance(document.getElementById("scroll_MSPSA208M"), 'y')
    },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
   // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    if (this.$refs !== undefined && this.$refs.bottomSheet_208M !== undefined) {
      this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheet_208M, false)
    }
  },  
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/    
  methods: {
    rule_NewGroupName_chk1() {
        this.grpNmFlag = false
        this.lv_newGrpNm = this.lv_NewGroupName.replace(/\#\$SA\$\#/g, ' ')
        
        if ((this.$bizUtil.getByteLength(this.lv_newGrpNm) + this.lv_newGrpNm.length) > 60) {
          this.grpNmFlag = true
        }
      return this.grpNmFlag 

    },
    rule_NewGroupName_chk2() {
      this.existedFlag = false
      this.lv_newGrpNm = this.lv_NewGroupName.replace(/\#\$SA\$\#/g, ' ')
      if (this.lv_OldGroupName !== this.lv_newGrpNm) {
        for (let i = 0; i < this.lv_BkmrkDirItems.length; i++) {             // 그룹명 중복건 체크
          if (this.lv_BkmrkDirItems[i].bkmrkGrpNm.replace(/\#\$SA\$\#/g, ' ') === this.lv_newGrpNm) {
              this.existedFlag = true     //  그룹명 중복건 존재 확인 
          }
        }
      }  
      return this.existedFlag
    },
    
    fn_setVoVal(scrnCol) {
        let val = this[scrnCol]
        let rtnVal = ''
        let col = this.$bizUtil.replaceAll(scrnCol, 'scrn', '')
        col = col.substring(0, 1).toLowerCase() + col.substring(1)
        let chkCols = ''

        // 그룹명 선택시 확인버튼 활성화 처리 = 'lv_NewGroupName|scrnRtmAftMntLvgct'
        chkCols = 'lv_NewGroupName'
        if(chkCols.search(scrnCol) > -1){
          if(this.$bizUtil.isEmpty(val) || val == 0){
            this.conFirmDisable = true
          }else{
            this.conFirmDisable = false
          }
        }
    },    
    /*********************************************************
     * Function명: fn_SelBkmrkDir
     * 설명: 나의보관함 그룹 조회
     * Params: 나의보관함 관리화면에서 자료 추가시 자료 제공 그룹 VO
     * Return: N/A
     *********************************************************/
    fn_SelBkmrkDir() {

     console.log('@@@ My 보관함 그룹조회(fn_SelBkmrkDir) 최초 조회 START... ☞ ' + new Date().getTime())
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      
      let lv_Vm = this
      this.lv_IsBkmrkDirLoading = true
      let pParams = {}
      let trnstId = ''
      

      pParams.cnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userId // 컨설턴트 번호
      pParams.moblInqrYn = 'Y' // 모바일 조회 여부

      trnstId = 'txTSSSA02S1'

      const _auth = 'S'

      this.post(lv_Vm, pParams, trnstId, _auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_Vm.$commonUtil.isSuccess(response)) {
          // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우              
              let reponseBody = response.body                        // 응답 데이터 처리
                  // lv_Vm.msgTxt = reponseBody.iCCntntBasInfoVO
              lv_Vm.lv_BkmrkDirVOList = reponseBody.iCBkmrkCntntVO
              
              if(null != lv_Vm.lv_BkmrkDirVOList && lv_Vm.lv_BkmrkDirVOList.length > 0){
                // 서비스 통신 조회키
                if (response.trnstComm.stndKeyList != null && response.trnstComm.stndKeyList.length > 0) {
                  lv_Vm.lv_BkmrkDirStndKeyList[0] = response.trnstComm.stndKeyList[0]
                } else {
                  lv_Vm.lv_BkmrkDirStndKeyList = []
                }

                // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
                lv_Vm.lv_BkmrkDirItems = lv_Vm.lv_BkmrkDirVOList.slice()

                // 더보기 키값
                if (lv_Vm.lv_BkmrkDirStndKeyList !== null    && lv_Vm.lv_BkmrkDirStndKeyList.length > 0 && 
                    lv_Vm.lv_BkmrkDirStndKeyList[0] !== null && lv_Vm.lv_BkmrkDirStndKeyList[0].stndKeyVal !== null 
                                                            && lv_Vm.lv_BkmrkDirStndKeyList[0].stndKeyVal.trim() !== '') {               
                    lv_Vm.lv_isBkmrkDirMoreData = true      // 다음페이지 데이터 존재함
                } else {            
                    lv_Vm.lv_isBkmrkDirMoreData = false     // 다음페이지 데이터 존재안함(마지막 페이지)
                }

                lv_Vm.lv_IsBkmrkDirLoading = false         // 데이터가 응답(완료)되었으므로 false

                
                lv_Vm.lv_BkmrkGrpCntntItems = []            // 특정 그룹내 조회된 자료 목록 저장하는 Array 초기화

                lv_Vm.fn_bs_bottomSheet_208M_open()         // 삭제/편집 버튼 시트 활성화 
                
              }  else {
                lv_Vm.fn_bs_bottomSheet_208M_close()         // 삭제/편집 버튼 시트 비활성화 
              }

              console.log('>>>> MSPSA208M   그룹조회(fn_SelBkmrkDir) 최초 조회 END')
            } else {                                      // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else {                                         // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheet_208M, false)
          }
        }, function (error) {
          return Promise.reject(error)                     // 서버 에러 처리
        })
        .catch(function (error) {
           window.console.log('#### MSPSA208M ===> error 발생... ☞ ' + error)
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_CheckExistedMySalesGroupName
     * 설명: My 보관함 새그룹 생성 및 그룹명 변경 시 사용자 입력 그룹명 중복 체크
     * Params: val {String}: 그룹명(신규 그룹명 또는 그룹명 변경시 변경 그룹명) <필수>
     *         processFlagCode {String}: 'I'(신규), 'U'(변경) <필수>
     * Return: N/A
     *********************************************************/
    fn_CheckExistedMySalesGroupName (processFlagCode) {
      console.log('@@@   My 보관함 그룹명 중복 검사(fn_CheckExistedMySalesGroupName) START')

        let tempGrpNm = this.lv_NewGroupName.replace(/\#\$SA\$\#/g, ' ')    //신규-변경 그룹명
        //console.log('test', this.grpNmFlag)
        //console.log('test', this.existedFlag)

      if (!this.grpNmFlag && !this.existedFlag )  {
            if (processFlagCode === 'I') {
              this.fn_CreateNewGroup(tempGrpNm, processFlagCode, '')      // "I"새그룹생성--중복이 아닐 경우는 그룹명 추가 및 변경 작업 진행
            } else {
                  // "U"-그룹명 변경 -- 중복이 아닐 경우는 그룹명 추가 및 변경 작업 진행
                  if (this.lv_OldGroupName === this.lv_NewGroupName) {
                    this.fn_bs3_modifyGroup_close()   // 그룹명 변경 버텀시트 비활성화 
                  } else {  
                      this.lv_SelectedBkmrkGroup  = this.lv_BkmrkDirVOList[this.radio].bkmrkGrpNm.replace(/\#\$SA\$\#/g, ' ')  // 변경전 그룹명
                      this.fn_CreateNewGroup(tempGrpNm, processFlagCode, this.lv_SelectedBkmrkGroup)
                  }   
             }
      } 
 
    },
    /*********************************************************
     * Function명: fn_CreateNewGroup
     * 설명: My 세일즈북 새그룹 생성 팝업 종료
     * Params: val {String}: 그룹명(신규 그룹명 또는 그룹명 변경시 변경 그룹명) <필수>
     *         processFlagCode {String}: 'I'(신규), 'U'(변경) <필수>
     *         orginGroupName {String}: 그룹명 변경 시 변경전 그룹명 <옵션>
     * Return: N/A
     *********************************************************/
    fn_CreateNewGroup (val, processFlagCode, orginGroupName) {
      console.log('##  My 보관함  새그룹 생성 ' + this.lv_NewGroupName + ' ' + orginGroupName + '   START')
        
      let lv_Vm = this
      let pParams = {}
      // 컨설턴트사번
      pParams.cnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userId
      // 모바일 조회 여부
      pParams.moblInqrYn = 'Y'
      // 프로세스 종류
      pParams.prcsfCd = processFlagCode

      if (processFlagCode === 'I') { // 신규 그룹 생성일 경우
        pParams.bkmrkGrpNm = val // 그룹명 파라미터에 신규 그룹명 저장
      } else { // 그룹명 변경일 경우
        pParams.bkmrkGrpNm = orginGroupName // 그룹명 파라미터에 이전 그룹명 저장
        pParams.mdfcBkmrkGrpNm = val // 변경 그룹명 파라미터에 변경 그룹명 저장
      }

      let trnstId = 'txTSSSA02P1' // 트랜잭션 ID
      const auth = processFlagCode // 신규(I) 및 변경(U)

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                lv_Vm.fn_SelBkmrkDir()    // 나의보관함 그룹 조회                      
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              if (response.msgComm.msgCd != null && response.msgComm.msgCd.length > 0) {
                //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            if (response.msgComm.msgCd != null && response.msgComm.msgCd.length > 0) {
              //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }

          if (processFlagCode === 'I') {
                lv_Vm.fn_bs4_newGroup_close()      // 새그룹생성 
          } else {                
                lv_Vm.fn_bs3_modifyGroup_close()   // 그룹명 변경 버텀시트 비활성화 
          }

          console.log('##  My 보관함  새그룹 생성  END')
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
           console.log('%%%  My 보관함  새그룹 생성 및 편집시 error 발생... ☞ ' + error)
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: fn_CheckDeleteBkmrkGroup
     * 설명: 선택 그룹 삭제 전 선택 그룹내 자료 유무에 따른 Confirm창 노출
     *       -  선택한 나의보관함 그룹내 세부목록 자료조회 = trnstId = 'txTSSSA03S1'
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_CheckDeleteBkmrkGroup() {

      console.log('>>>delete detail  MSPSA003M  My 보관함 세부목록  조회 START... ☞ ' + this.radio + ' '  + new Date().getTime())

      //  this.fn_bs_bottomSheet_208M_close()    //  기본 버텀싯트  false처리 
      this.lv_BkmrkGrpCntntItems = []            // 특정 그룹내 조회된 자료 목록 저장하는 Array 초기화

      this.lv_IsBkmrkGrpCntntLoading = true
      this.lv_searchGroupName = this.lv_BkmrkDirVOList[this.radio].bkmrkGrpNm.replace(/\#\$SA\$\#/g, ' ')  // 삭제대상 그룹명
      let lv_Vm = this
      let pParams =
      {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트 번호
        bkmrkGrpNm: lv_Vm.lv_searchGroupName, // 선택된 그룹명,
        moblInqrYn: 'Y' // 모바일 조회 여부
      }

      let trnstId = 'txTSSSA03S1'
      const _auth = 'S'

      // NextKey 방식의 페이징 정보 초기화
      lv_Vm.stndKeyList = []

      this.post(lv_Vm, pParams, trnstId, _auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              let reponseBody = response.body// 응답 데이터 처리

              lv_Vm.lv_BkmrkGrpCntntVOList = reponseBody.iCBkmrkCntntVO
              if(null != lv_Vm.lv_BkmrkGrpCntntVOList && lv_Vm.lv_BkmrkGrpCntntVOList.length > 0){
                lv_Vm.lv_BkmrkGrpCntntItems = lv_Vm.lv_BkmrkGrpCntntVOList.slice()// 검색 후, 전체 데이터를 다시 보여주기 위해 저장

                // 데이터가 응답(완료)되었으므로 false
                lv_Vm.lv_IsBkmrkGrpCntntLoading = false

                console.log('@@@@ MSPSA003M  나의보관함 ' + lv_Vm.lv_searchGroupName + ' 컨텐츠 최초 조회 END... ☞ ' + new Date().getTime())

                if (lv_Vm.lv_BkmrkGrpCntntItems !== null && lv_Vm.lv_BkmrkGrpCntntItems.length > 0) {
                    lv_Vm.fn_bs1_deleteGroupDetail_open()                  // 선택된 그룹내 자료가 있을 경우 Confirm 창 출력
                } else {
                    lv_Vm.fn_bs2_deleteGroup_open()                        // 선택된 그룹내 자료가 없을 경우 Confirm 창 출력
                }
              } else {
                console.log('@@@@ MSPSA003M  나의보관함 ss031' , lv_Vm.lv_BkmrkGrpCntntVOList )
                    lv_Vm.fn_bs2_deleteGroup_open()                        // 선택된 그룹내 자료가 없을 경우 Confirm 창 출력                
              }

         } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
             //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
             lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }

        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
           console.log('@@@@ MSPSA003M  나의보관함  error 발생... ☞ ' + error)
          window.vue.error(error)
        })

    },

    /*********************************************************
     * Function명: fn_OnConfirmDeleteBkmrkGroupWithContent
     * 설명: 그룹내 자료가 있는 그룹을 삭제할 경우에 출력되는 Confirm 창의 확인 버튼 이벤트 핸들러
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnConfirmDeleteBkmrkGroupWithContent() {
      this.fn_DeleteBkmrkGroup()                // 삭제 진행처리 
      this.fn_bs1_deleteGroupDetail_close()     // 선택된 그룹내 자료가 있을 경우 Confirm 창 비활성화
    },
    /*********************************************************
     * Function명: fn_OnConfirmDeleteBkmrkGroupNoneContent
     * 설명: 그룹내 자료가 없는 그룹을 삭제할 경우에 출력되는 Confirm 창의 확인 버튼 이벤트 핸들러
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnConfirmDeleteBkmrkGroupNoneContent() {

      this.fn_DeleteBkmrkGroup()                             // 삭제 진행처리 
      this.fn_bs2_deleteGroup_close()                        // 선택된 그룹내 자료가 없을 경우 Confirm 창 비활성화 
    },
    /*********************************************************
     * Function명: fn_DeleteBkmrkGroup
     * 설명: 선택된 그룹 삭제
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_DeleteBkmrkGroup() {

      console.log(' @@@  My 보관함 그룹 삭제(fn_DeleteBkmrkGroup) START... ☞ ' + new Date().getTime())

      let lv_Vm = this
      lv_Vm.lv_IsBkmrkGrpCntntLoading = true

      // 선택된 그룹명
      this.lv_searchGroupName  = this.lv_BkmrkDirVOList[this.radio].bkmrkGrpNm.replace(/\#\$SA\$\#/g, ' ')     // 삭제대상 그룹명
      let pParams =
      {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트 번호
        bkmrkGrpNm: lv_Vm.lv_searchGroupName, // 선택된 그룹명
        moblInqrYn: 'Y' // 모바일 조회 유무
      }

      let trnstId = 'txTSSSA03D1' // 트랜잭션 ID
      const auth = 'D' // 권한

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
              // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_Vm.$commonUtil.isSuccess(response)) {
              // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                lv_Vm.fn_ontostMesg()                              // 삭제처리 완료 메세지     
                lv_Vm.fn_SelBkmrkDir() // 삭제 후 나의보관함. 그룹 재로딩
                console.log(' @@@  My 보관함 그룹 삭제(fn_DeleteBkmrkGroup) END... ☞ ' + new Date().getTime())
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
                  //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                  lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                  //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                  lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
           console.log('@@@  My 보관함 그룹 삭제(fn_DeleteBkmrkGroup) error 발생... ☞ ' + error)
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_SelBkmrkGrpCntnt
     * 설명: 선택 그룹내  세부 자료 목록 조회
     * Params: 선택한 나의보관함 그룹내 세부목록 자료조회 후 자료 존재시 자료목록팝업으로 화면이동
     * Return: N/A
     *********************************************************/
    fn_OnSelectedBkmrkGroup(itemGroupNm) { 
      console.log('>>>  MSPSA208M  My 보관함 세부목록 화면전환  조회 START... ☞ ' + new Date().getTime())

      this.lv_searchGroupName = itemGroupNm.replace(/\#\$SA\$\#/g, ' ')  // 삭제대상 그룹명
       
      let lv_Vm = this
      let pParams =
      {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트 번호
        bkmrkGrpNm: lv_Vm.lv_searchGroupName, // 선택된 그룹명,
        moblInqrYn: 'Y' // 모바일 조회 여부
      }

      let trnstId = 'txTSSSA03S1'
      const _auth = 'S'

      this.post(lv_Vm, pParams, trnstId, _auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              let reponseBody = response.body// 응답 데이터 처리

              lv_Vm.lv_BkmrkGrpCntntVOList = reponseBody.iCBkmrkCntntVO
              lv_Vm.lv_BkmrkGrpCntntItems = lv_Vm.lv_BkmrkGrpCntntVOList.slice()// 검색 후, 전체 데이터를 다시 보여주기 위해 저장

             if (lv_Vm.lv_BkmrkGrpCntntItems !== null && lv_Vm.lv_BkmrkGrpCntntItems.length > 0) {
                 lv_Vm.fn_OpenMSPSA209P()     //  그룹스크랩 조회 popup 호출
              } else {
                    // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                    lv_Vm.getStore('confirm').dispatch('ADD',   '선택한 그룹에 스크랩한 자료가 없습니다.' )
              }   

             console.log('@@@@ MSPSA208M  나의보관함 세부목록 화면전환' + lv_Vm.lv_searchGroupName + ' 조회 END... ☞ ' + new Date().getTime())
              
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD',  '선택한 그룹에 스크랩한 자료가 없습니다.' )
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
             //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
             lv_Vm.getStore('confirm').dispatch('ADD', ' 선택한 그룹에 스크랩한 자료가 없습니다.' )
          }

        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
           console.log('@@@@ MSPSA208M  나의보관함 세부목록 화면전환 error 발생... ☞ ' + error)
          window.vue.error(error)
        })
    },
  /*********************************************************
     * Function명: fn_OpenMAP209P
     * 설명: 그룹별 리스트 관리 POPUP 호출
     *********************************************************/
    fn_OpenMSPSA209P() {
       console.log("MSPSA208M ---> fn_OpenMSPSA209P")
 
      const lp_Vm = this  
      this.popup209 = this.$bottomModal.open(MSPSA209P, {
        properties: {
          pPage: 'MSPSA208M',
          psearchGroupName: this.lv_searchGroupName, // 그룹명 
        },
        listeners: {
          onPopupSearch: (pData) => {
            lp_Vm.$bottomModal.close(this.popup209)   // 모달 닫기
            this.fn_SelBkmrkDir()     // 나의보관함 그룹 조회
          },
          onPopupClose: () => {
            lp_Vm.$bottomModal.close(this.popup209)   // 모달 닫기
          }
        }
      })
    },

    /*********************************************************
     * Function명: 기타  function control
     * 설명:  
     *********************************************************/

      fn_ClickBtnPrevious() {
          this.$router.go(-1)
      },

      fn_ontostMesg(){
        //this.$refs.stackableSnackbar.show({text:'삭제되었습니다.'})
        this.getStore('toast').dispatch('ADD', '삭제되었습니다.')
      },
      
      fn_bs_bottomSheet_208M_open() { 
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheet_208M, true)
      },

      fn_bs_bottomSheet_208M_close() { 
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheet_208M, false)
      },

      fn_bs1_deleteGroupDetail_open() { 
         this.$refs.nsbottomsheet1.open() 
      },

      fn_bs1_deleteGroupDetail_close() { 
        if  (!this.lv_IsBkmrkDirLoading) {       // 데이터가 응답(완료)되었으므로 false
              this.$refs.nsbottomsheet1.close()
              this.fn_bs_bottomSheet_208M_open()         // 삭제-편집 버튼 시트 활성화 
        } else {
              this.$refs.nsbottomsheet1.close()
        }
      },

      fn_bs2_deleteGroup_open() { 
        this.$refs.nsbottomsheet2.open()
      },

      fn_bs2_deleteGroup_close() { 
        if  (!this.lv_IsBkmrkDirLoading) {       // 데이터가 응답(완료)되었으므로 false
              this.$refs.nsbottomsheet2.close()
              this.fn_bs_bottomSheet_208M_open()         // 삭제-편집 버튼 시트 활성화 
        } else {
              this.$refs.nsbottomsheet2.close()
        }        
      },
 
      fn_bs3_modifyGroup_open() {
        this.$refs['editGroupNmRef'].focus()
        this.lv_NewGroupName = this.lv_BkmrkDirVOList[this.radio].bkmrkGrpNm.replace(/\#\$SA\$\#/g, ' ')    
        this.lv_OldGroupName = this.lv_NewGroupName
        this.$refs.nsbottomsheet3.open()
      },

      fn_bs3_modifyGroup_close() { 
        this.$refs.nsbottomsheet3.close()
      },

      fn_bs4_newGroup_open() { 
        this.$refs['newGroupNmRef'].focus()
        this.lv_NewGroupName = '' 
        this.$refs.nsbottomsheet4.open() 
      },

      fn_bs4_newGroup_close() {
         this.$refs.nsbottomsheet4.close()
      },
     fn_HistoryBack () {
       this.$router.push({name: 'MSPBC002M'})  // 이전화면으로 이동
        // 체크박스가 있는 경우
        // if (this.lv_BkmrkDirVOList.length > 0 ) {
        //         let alertMsg = '현재 화면을 종료하시겠습니까?'

        //         let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        //           properties: {
        //               type: 'B',
        //               content: alertMsg,
        //               title_pos_btn: '나가기'
        //           },
        //           listeners: {
        //             onPopupConfirm: () => {
        //              this.$bottomModal.close(alertObj)                
        //               // 특정화면 이동 혹은 이전 화면 이동
        //               this.$router.go(-1)
        //            },
        //             onPopupClose: () => {
        //              this.$bottomModal.close(alertObj)
        //             }
        //          }
        //        })
        // } else {
         //   this.$router.go(-1) // 이전화면으로 이동
         //}
      }      
  }    
};
</script>