/*
* 업무구분:  안내자료
* 화면 명:   나의보관함_스크랩 (MSPSA209P)
* 화면 설명: 나의보관함  그룹스크랩자료 관리
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp txtSkip" :title="lv_titleNm" :show-title="true" type="subpage"  @on-header-left-click="fn_HistoryBack" :topButton="true">
    <template #frame-header-fixed>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck"  @click="fn_SelectAllItems()" :disabled="checkDisabled"></mo-checkbox>
          <div  class="list-item__contents">            
            <div class="list-item__contents__title">
               <span class="sum">{{ checkCount > 0 ? checkCount + '건 선택' : '총 ' + lv_BkmrkGrpCntntVOList.length + '건' }}</span> 
            </div>
          </div>   
          <!--
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum"></span>
            </div>
          </div>
          -->
          <div class="list-item__btn">
            <mo-button class="box-arrow down" :disabled="isEmptyView">
              <mo-icon icon="msp-arrow-down" @click="fn_MoveUntySlmtItems(lv_BkmrkGrpCntntVOList, lv_SelectedUntySlmtItems, 'down')" ></mo-icon>
            </mo-button>
            <mo-button class="box-arrow up ml6" :disabled="isEmptyView">
              <mo-icon icon="msp-arrow-down" @click="fn_MoveUntySlmtItems(lv_BkmrkGrpCntntVOList, lv_SelectedUntySlmtItems)" ></mo-icon>
            </mo-button> 
          </div>
          <div class="list-item__btn">
            <mo-button class="ns-btn-round white ml14" @click="fn_SaveBkmrkCntntSno()" :disabled="canSave" >순서저장</mo-button>
          </div>	
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container v-if="!lv_IsBkmrkGrpCntntLoading" alignV="start" componentid="" direction="column" class="ns-check-list mb146">
        <mo-list :list-data="lv_BkmrkGrpCntntVOList">
          <template #list-item="{item}">
            <mo-list-item class="bd-b-Ty1"> <!-- 퍼블추가 : bd-b-Ty1 -->
              <!-- 체크박스 -->
              <mo-checkbox v-model="item.checked"  @input="fn_CheckSelectedItemCount()" :disabled="checkDisabled" />            
              <div class="list-item__contents" @click="fn_GoDetailsView(item)"> 
                <div class="list-item__contents__title fexTy5">
                  <span class="name txtSkip fwn" >{{item.untySlmtNm}}</span>
                  <mo-badge class="badge-sample-badge lightgray2 sm" text="본인" shape="status">{{item.untySlmtClsfNm}}</mo-badge> 
                </div>
              </div>  
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <ur-box-container v-else  alignV="start" componentid="ur_box_container_001" direction="column" class="no-data no-scrap">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray"><p class="mt10">스크랩한 자료가 없습니다.</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>  
      <!-- no-data end -->

    

      <!-- 자료삭제 시도 시 -->
      <mo-bottom-sheet ref="delete_nsbottomsheet1" class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">선택한 자료를 삭제하시겠습니까?</p>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_deleteConfirm_close()" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnConfirmDeleteContent()" name="삭제">삭제</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 토스트 -->
      <mo-snackbar ref="stackableSnackbar" stackable :duration="3000" />
 
      <!-- 상세보기 팝업 ns-bottom-sheet closebtn title_none-->
      <mo-bottom-sheet ref="bottomSheetPSCM_209P"  class="ns-bottom-sheet dsN-background title_none"> <!-- 퍼블 수정 :  dsN-background (ns0000001)  --> 
        <template v-slot:action>
          <div class="action-area">
            <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
              <div class="relative-div">
                <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white ls--2"  @click="fn_deleteConfirm_open()">자료삭제</mo-button>
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ReadySelectedItemsViewDetails()">상세보기</mo-button>
              </div>
            </ur-box-container>
          </div>
          <ur-box-container componentid="" direction="row">
            <div class="btn_icon_box--jjh">
                <mo-button componentid="" class="ns-btn-round message" @click="fn_SMSBtnClicked()">문자</mo-button>
                <mo-button componentid="" class="ns-btn-round email_link" @click="fn_OpenPopEmailCustList()">이메일</mo-button>
                <mo-button componentid="" class="ns-btn-round print" @click="fn_print_BottomSheet_open()">인쇄</mo-button>
                <mo-button componentid="" class="ns-btn-round scrap" @click="fn_OpenMSPSA210P()" >스크랩</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>  
      <!-- 상세보기 팝업 //-->

      <mo-bottom-sheet ref="print_bottomSheet"  class="ns-bottom-sheet closebtn">
               <template v-slot:title>
                인쇄
                  <div class="ns-btn-close" @click="fn_print_BottomSheet_close()" name="닫기"></div>
               </template>
       
               <ur-box-container direction="column" alignV="start">        
                  <ur-box-container componentid="namecard">          
                    <ur-box-container componentid="" direction="row" class="fexTy5 pl24 mb30">
                      <span class="mr35">매수</span>
                          <mo-stepper v-model="lv_SpinValue" :value=1  :min="1" :max="20"/>
                    </ur-box-container>
                  </ur-box-container>
               </ur-box-container>

             <template v-slot:action>
              <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
                <div class="relative-div">
                  <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_print_BottomSheet_close()">취소</mo-button>
                     <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzConfirmBtnClicked()">인쇄</mo-button>
                </div>
              </ur-box-container>
            </template>
      </mo-bottom-sheet>
      
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import SpConstants from '@/config/constants/spConstants'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import SearchOrgForCM from '@/ui/cm/SearchOrgForCM.vue'
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPSA210P from '@/ui/sa/MSPSA210P'
import MSPSA074P from '@/ui/sa/MSPSA074P'
import MSPCM128P from '@/ui/cm/MSPCM128P'
import MSPCM152P from '@/ui/cm/MSPCM152P'
import MSPCM154P from '@/ui/cm/MSPCM154P'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPSA209P',
  // 현재 화면 ID
  screenId: 'MSPSA209P',
  // 컴포넌트 선언
  components: {
   'MSPSA210P': MSPSA210P,  
   'MSPSA074P': MSPSA074P,
   'MSPCM152P': MSPCM152P,
   'MSPCM128P': MSPCM128P,
   'MSPCM154P': MSPCM154P,
    MspBottomSelect,
    SearchOrgForCM
  },

  isBackKeyHandle: true ,  // 팝업일때 디바이스 백키 직접 핸들처리여부 오션

  // 화면명
  props: {
    pPage: String, // 부모 페이지명
    psearchGroupName: String, // 그룹명
    pCustInfo: {},   // 이메일발송
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      toastMsg: '', // 문자발송 toast 문구
      lv_titleNm: '', // TITLE 그룹명
      lv_ReturnVal: {}, // 리턴값
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      isSucs: false, // 리턴값 세팅여부

      // 조직
      lv_SelectOrg: {}, // 조직 컴포넌트 데이터
      pOrgData209P: {}, // 조직정보
      lv_Popup210: {},  // 나의보관함추가popup
      lv_Popup152: {},  // 고객수신선택popup
      lv_Popup128: {},  // sms발송popup
      lv_Popup154: {},  // 터치온발송popup
      lv_Popup074: {},  // 이메일발송popup
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부

      // nextKey 방식의 페이징 관련 정보 저장
      // http.js에 정의된 변수를 overriding
      stndKeyList: [],
      // 한번에 호출할 컨텐츠 건수
      // http.js에 정의된 변수를 overriding
      pageRowCnt: 100,

      // 리스트 컨트롤 
      canSave: true,     //순서저장 버튼 
      isOneOrMoreCheck: false,  
      bottomDisabled: true,  // 화면제어용
      isEmptyView: true, // 순서변경 화살표 
      checkDisabled: false, // 체크박스 비활성화 여부      
  
  // 선택된 자료 목록 저장
      lv_SelectedUnitySmltItemIndex: -1,
      lv_SelectedUntySlmtItems: [],
      // 내가사용한 자료 목록 관련 변수
      lv_IsBkmrkGrpCntntLoading: false,
      // 내가사용한 자료 목록
      lv_BkmrkGrpCntntVOList: [],
      // 선택 그룹내 자료 전체 목록
      lv_BkmrkGrpCntntItems: [],
      // 서비스 조회 파라미터 (필요시 parameter 추가)
      lv_BkmrkGrpCntntParams: {
        BkmrkGrpCntntPageSize: 20
      },
      // 더 이상 조회할 데이터가 있는지 여부
      lv_isBkmrkGrpCntntMoreData: true,
      // nextKey방식의 페이징 변수
      lv_BkmrkGrpCntntStndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
      lv_VodItemStyle: '',
 
     // 고객목록 Object-popup
      custListObj: {},
      
      //상세보기관련 
            // 단건 자료 선택
      lv_SelectedSingleItem: null,
      // 공용 선택된 아이템 리스트, child의 리스트들과 동기화 됨
      // swipe-tap 에서 선택된 대분뷰명=> 라이브러리 or 사랑On도움방 대분류 선택시 안드로이드 네이티브 pdf뷰에서 "SMS" 메뉴 안보이기 
      lv_SelcectedClsfSytmNm: '',
      lv_SelectedItemList: [],
      lv_SelectedFomItemList: [],
      lv_IsMadeMultiItem: true,  // 다건 자료 선택,
      lv_SADCFmlmtSVOList: [],   // PDF 파일 생성 VO 목록
      lv_CompletedPDFFileVOList: [],  // PDF 생성 완료된 파일 정보를 저장하는 Array
      lv_CheckDocsRetryNumberOfTimes: 0,   // PDF 생성 여부 판단 반복 횟수

      //인쇄관련 
      pIsSelectedMultiItem:  false,  // 다건 자료선택여부  
      lv_IsIncludeCnsltInfo: 'Y', // 컨설턴트 개인정보 이미지 포함 여부
      lv_SpinValue: 1,            // 인쇄매수 
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
 /** computed 함수 정의 영역 */
  computed: {
    checkCount () {
      console.log("MSPSA209P---> computed") , this.lv_BkmrkGrpCntntVOList.filter(item => item.checked).length
      return this.lv_BkmrkGrpCntntVOList.filter(item => item.checked).length
    }
  },
    /** watch 정의 영역 */
  watch: {
    checkCount () {
      console.log("MSPSA209P---> watch")
      this.fn_CheckSelectedItemCount()
      if (this.checkCount === this.lv_BkmrkGrpCntntVOList.length && this.lv_BkmrkGrpCntntVOList.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
     }
    },

 
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {

    // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 공통코드 조회
    let codeList = []
    switch (this.pPage) {
      // 전체 
      case 'MSPSA208M':
        console.log("MSPSA209P---> created >> " + this.psearchGroupName)
        this.lv_titleNm = this.psearchGroupName
        this.fn_SelBkmrkGrpCntnt()
    }    
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPSA209P')
    
    //this.fn_SelBkmrkGrpCntnt()
    // 조직정보 세팅
    this.pOrgData209P = this.pOrgData
    
  },
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
   // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },    
 
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_SelectAllItemsFunc
     * 설명: 선택된 그룹내 전체 자료 선택 이벤트 핸들러
     * Params: lv_UntySlmtItemsSelectAll (Boolean) : 모두 선택 여부<필수>
     * Return: N/A
     *********************************************************/
    fn_SelectAllItems() {
      console.log("MSPSA209P---> fn_SelectAllItems >> ", this.lv_BkmrkGrpCntntVOList , '[]', this.isOneOrMoreCheck )
      this.$nextTick(() => {
        if (this.lv_BkmrkGrpCntntVOList.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.lv_BkmrkGrpCntntVOList) {
            if (this.isOneOrMoreCheck) {
              this.lv_BkmrkGrpCntntVOList[n].checked = true
            } else {
              this.lv_BkmrkGrpCntntVOList[n].checked = false
            }
          }
        }
      })      
    
     this.fn_CheckSelectedItemCount()
    },
    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount() {
      console.log("MSPSA209P-전체선택--> fn_CheckSelectedItemCount  start ")
      this.selectItems = [] // 초기화
      this.selectItems = this.lv_BkmrkGrpCntntVOList.filter(item => item.checked)
      this.lv_SelectedUntySlmtItems = this.selectItems.slice(0)
  
     // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      if (this.checkCount > 0) { 
        this.isEmptyView = false
        this.fn_BottomSheet_open()
      } else {
        this.isEmptyView = true
        this.fn_BottomSheet_close()
        this.lv_SelectedUntySlmtItems = []
      }

 

      console.log("MSPSA209P-전체선택--> fn_CheckSelectedItemCount  end ==> isEmptyView", this.isEmptyView )
    },    
    /*********************************************************
     * Function명: fn_SelBkmrkGrpCntnt
     * 설명: 선택 그룹내 포함된 자료 목록 조회
     * Params: 선택 그룹내 My 세일즈북 추가 대상인 자료 목록
     * Return: N/A
     *********************************************************/
    fn_SelBkmrkGrpCntnt() {
    console.log(' @@@@@ MSPSA209P 나의보관함 스크랩조회' + this.lv_titleNm + '   START... ☞ ' + new Date().getTime())

      this.lv_IsBkmrkGrpCntntLoading = true
      let searchGroupName = this.psearchGroupName.replace(/\#\$SA\$\#/g, ' ')
      let lv_Vm = this
      let pParams =
      {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트 번호
        bkmrkGrpNm: searchGroupName, // 선택된 그룹명,
        moblInqrYn: 'Y' // 모바일 조회 여부
      }

      let trnstId = 'txTSSSA03S1'
      const _auth = 'S'

      // NextKey 방식의 페이징 정보 초기화
      lv_Vm.stndKeyList = []

      this.post(lv_Vm, pParams, trnstId, _auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              let reponseBody = response.body// 응답 데이터 처리
              let responseList = lv_Vm.$bizUtil.cloneDeep(response.body.iCBkmrkCntntVO)
              lv_Vm.lv_BkmrkGrpCntntItems = response.body.iCBkmrkCntntVO.slice()  // 검색 후, 전체 데이터를 다시 보여주기 위해 저장

              for (let i = 0; i < responseList.length; i++) {                
                  responseList[i].checked = false
               }
 
               lv_Vm.lv_BkmrkGrpCntntVOList = responseList

              if (response.trnstComm.stndKeyList != null && response.trnstComm.stndKeyList.length > 0) {
                lv_Vm.lv_BkmrkGrpCntntStndKeyList[0] = response.trnstComm.stndKeyList[0]
              } else {
                lv_Vm.lv_BkmrkGrpCntntStndKeyList = []
              }

              // lv_Vm.lv_BkmrkGrpCntntItems = lv_Vm.lv_BkmrkGrpCntntVOList.slice()  // 검색 후, 전체 데이터를 다시 보여주기 위해 저장


              // 선택 그룹된 자료 목록의 순서를 강제로 설정
              // 화살표를 이용해 순서를 변경할 경우 changeSeq만 변화하고, originSeq는 그대로 유지
              // originSeq와 changeSeq가 일치하지 않을 경우는 순서가 변경된 것으로 간주
              // 순서 저장 시 originSeq에 changeSeq 값을 할당하여 변경된 순서를 서버에 전달
              lv_Vm.lv_BkmrkGrpCntntItems.forEach((item, index) => {
                item.originSeq = index
                item.changeSeq = index
              })

              // 더보기 키값
              if (lv_Vm.lv_BkmrkGrpCntntStndKeyList !== null && lv_Vm.lv_BkmrkGrpCntntStndKeyList.length > 0 && lv_Vm.lv_BkmrkGrpCntntStndKeyList[0] !== null && lv_Vm.lv_BkmrkGrpCntntStndKeyList[0].stndKeyVal !== null && lv_Vm.lv_BkmrkGrpCntntStndKeyList[0].stndKeyVal.trim() !== '') {
                // 다음페이지 데이터 존재함
                lv_Vm.lv_isBkmrkGrpCntntMoreData = true
              } else {
                // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.lv_isBkmrkGrpCntntMoreData = false
              }

              // 데이터가 응답(완료)되었으므로 false
              lv_Vm.lv_IsBkmrkGrpCntntLoading = false
              lv_Vm.fn_CheckSelectedItemCount()

              console.log('>>>>> MSPSA209P 나의보관함 스크랩조회  ' + lv_Vm.psearchGroupName + ' 컨텐츠 최초 조회 END... ☞ ' + new Date().getTime())
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            // lv_Vm.lv_SearchFlg = 2
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }

        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          if (lv_Vm.DEBUG_MODE_CON) window.console.log('##### MSPSA209P 나의보관함 스크랩조회 error 발생... ☞ ' + error)
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_OnConfirmDeleteContent
     * 설명: 선택 그룹내 선택된 자료 목록 삭제 시 노출된 Confirm 창의 확인 버튼 클릭 시
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnConfirmDeleteContent() {
      console.log('@@@ MSPSA209M >>>  나의보관함 스크랩 자료삭제 (fn_DeleteUntySlmtItem) START... ☞ ' + new Date().getTime())

      this.lv_IsBkmrkGrpCntntLoading = true
      this.selectItems = []
      this.selectItems = this.lv_BkmrkGrpCntntVOList.filter(item => item.checked)
      // 선택된 그룹 명
      let searchGroupName = this.psearchGroupName.replace(/\#\$SA\$\#/g, ' ')
      let lv_Vm = this

      // 파라미터 설정
      let pParams =
      {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트 번호
        bkmrkGrpNm: searchGroupName, // 선택된 그룹명
        moblInqrYn: 'Y', // 모바일 조회 여부
        iCBkmrkCntntVO: lv_Vm.selectItems // 삭제할 자료 목록
      }

      let trnstId = 'txTSSSA03D2'
      const _auth = 'D'

      this.post(lv_Vm, pParams, trnstId, _auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_Vm.$commonUtil.isSuccess(response)) {
          // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                 console.log('@@@ MSPSA209M >>>  나의보관함 스크랩 자료삭제(fn_DeleteUntySlmtItem) END... ☞ ' + new Date().getTime())
                  lv_Vm.fn_SelBkmrkGrpCntnt()                        // 해당 그룹의 자료 목록을 재 호출
                  lv_Vm.fn_ontostMesg()                              // 삭제처리 완료 메세지    
            } else {                                            // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else                                               { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            //lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }

        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          console.log('@@@ MSPSA209M >>>  나의보관함 스크랩 자료삭제 error 발생... ☞ ' + error)
          window.vue.error(error)
        })  
    },
  
    /*********************************************************
     * Function명: fn_MoveUntySlmtItems
     * 설명: 선택된 그룹내 자료 순서 변경
     * Params: oriItems (Array) : 순서 변경 전 자료 목록 <필수>
     *         target (Array) : 순서 변경 후 자료 목록 <필수>
     *         flag (String) : 위, 아래 순서 변경 방향
     * Return: N/A
     *********************************************************/
    fn_MoveUntySlmtItems (oriItems = [], target = [], flag = 'up') {
      console.log('@@@@ MSPSA209P >> 나의보관함 스크랩  순서 변경 fn_MoveUntySlmtItems START.. ☞ ' + new Date().getTime())

      this.canSave = false
      const targetItems = Array.prototype.slice.call(target)
      const mapped = oriItems.map((item, index) => ({index, isMoveTarget: targetItems.includes(item)}))
      let moveIndex = 0
      let changeItem = null

      for (let i = oriItems.length - 1; i >= 0; i--) {
        const item = mapped[i]
        const hasCalcIndex = !item.hasOwnProperty('calcIndex')
        if (item.isMoveTarget && hasCalcIndex) {
          moveIndex = i + (flag === 'up' ? -1 : 1)
          changeItem = mapped[moveIndex]
          item.calcIndex = moveIndex
          while (changeItem && changeItem.isMoveTarget) {
            changeItem.calcIndex = flag === 'up' ? --moveIndex : ++moveIndex
            changeItem = mapped[moveIndex]
          }
          changeItem && (changeItem.calcIndex = item.index)
        } else if (hasCalcIndex) {
          item.calcIndex = i
        }
      }

      mapped.sort((a, b) => {
        if (a.calcIndex > b.calcIndex) {
          return 1
        } else if (a.calcIndex < b.calcIndex) {
          return -1
        }
        return 0
      })

      // this.lv_BkmrkGrpCntntItems = mapped.map(item => oriItems[item.index])

      // this.lv_BkmrkGrpCntntItems.forEach((item, index) => {
      //   item.changeSeq = index
      //   item.stdilSno = index + 1 // 실제 저장되는 순서
      // })

      // for (let i = 0; i < this.lv_BkmrkGrpCntntItems.length; i++) {
      //   console.log('@@@@ MSPSA209P >> 나의보관함 스크랩  순서 변경 후 ☞ this.lv_BkmrkGrpCntntItems[' + i + '].untySlmtNm=' + this.lv_BkmrkGrpCntntItems[i].untySlmtNm + ',this.lv_BkmrkGrpCntntItems[' + i + '].originSeq=' + this.lv_BkmrkGrpCntntItems[i].originSeq + ',this.lv_BkmrkGrpCntntItems[' + i + '].changeSeq=' + this.lv_BkmrkGrpCntntItems[i].changeSeq + ',this.lv_BkmrkGrpCntntItems[' + i + '].stdilSno=' + this.lv_BkmrkGrpCntntItems[i].stdilSno)
      // }

      this.lv_BkmrkGrpCntntVOList = mapped.map(item => oriItems[item.index])

       this.lv_BkmrkGrpCntntVOList.forEach((item, index) => {
         item.changeSeq = index
         item.stdilSno = index + 1 // 실제 저장되는 순서
       })

       for (let i = 0; i < this.lv_BkmrkGrpCntntVOList.length; i++) {
         console.log('@@@@ MSPSA209P >> 나의보관함 스크랩  순서 변경 후 ☞ this.lv_BkmrkGrpCntntVOList[' + i + '].untySlmtNm=' + this.lv_BkmrkGrpCntntVOList[i].untySlmtNm + ',this.lv_BkmrkGrpCntntVOList[' + i + '].originSeq=' + this.lv_BkmrkGrpCntntVOList[i].originSeq + ',this.lv_BkmrkGrpCntntVOList[' + i + '].changeSeq=' + this.lv_BkmrkGrpCntntVOList[i].changeSeq + ',this.lv_BkmrkGrpCntntVOList[' + i + '].stdilSno=' + this.lv_BkmrkGrpCntntVOList[i].stdilSno)
       }      
    },
    /*********************************************************
     * Function명: fn_SaveBkmrkCntntSno
     * 설명: 선택 그룹 내 선택 자료 목록의 변경된 순서 저장
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SaveBkmrkCntntSno() {
      console.log('@@@@ MSPSA209P >> 나의보관함 스크랩 자료 순서 저장(fn_SaveBkmrkCntntSno) START... ☞ ' + new Date().getTime())
      let lv_Vm = this
      let searchGroupName = this.psearchGroupName.replace(/\#\$SA\$\#/g, ' ')    // 그룹명

      // 파라미터 설정
      let pParams =
      {
        cnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId, // 컨설턴트 번호
        bkmrkGrpNm: searchGroupName, // 선택된 그룹명
        moblInqrYn: 'Y', // 모바일 조회 여부
        iCBkmrkCntntVO: lv_Vm.lv_BkmrkGrpCntntVOList, // 삭제할 자료 목록
        mdfcBkmrkGrpNm: searchGroupName // 선택된 그룹명
      }

      let trnstId = 'txTSSSA03U1'
      const _auth = 'U'

      this.post(lv_Vm, pParams, trnstId, _auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              lv_Vm.fn_SelectAllItems() // 전체 선택 여부를 false로 설정
              lv_Vm.fn_SelBkmrkGrpCntnt() // 해당 그룹의 자료 목록을 재 호출
              lv_Vm.canSave = true // 순서변경 버튼 비활성화 
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
           // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }

          console.log('@@@@ MSPSA209P >> 나의보관함 스크랩 자료 순서 저장(fn_SaveBkmrkCntntSno) END... ☞ ' + new Date().getTime())
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          console.log('@@@@ MSPSA209P >> 나의보관함 스크랩 자료 순서 저장(fn_SaveBkmrkCntntSno) error 발생... ☞ ' + error)
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_GoDetailsView
     * 설명: 제목 클릭 시 자료 상세보기를 위해 선택 자료를 안내자료 컨테이너(TSSSA001M)으로 전달
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_GoDetailsView(pSelectedItem) {
      console.log('@@@ 나의보관함 스크랩 상세보기(fn_GoDetailsView) START ... ☞ ' + new Date().getTime())

      pSelectedItem.untySlmtClsfNm01 = pSelectedItem.untySlmtClsfNm
      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
      //this.lv_SelectedFomItemList = pSelectedItem  
      // this.fn_OnSelectedSingleItem(this.lv_SelectedFomItemList)
      this.fn_OnSelectedSingleItem(pSelectedItem)
      window.console.log('@@@ 나의보관함 스크랩 상세보기(fn_GoDetailsView) END ... ☞ ' + new Date().getTime())
        
    },
  /*********************************************************
    * Function명: fn_OnSelectedSingleItem
    * 설명: 
    * Params: target
    * Return: N/A
    *********************************************************/
    fn_OnSelectedSingleItem(pSelectedItem) {
      window.console.log('@@@ 나의보관함 스크랩 단건 상세보기(    fn_OnSelectedSingleItem   ) start ... ☞ ' + new Date().getTime())
      this.clickedSingleItem = true
      //pSelectedItem.checked = false
      this.lv_SelectedSingleItem = pSelectedItem
      //this.lv_SelectedItemList = []
      //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)

      // 단일 선택 후 상세보기 진행 시
      this.lv_IsMadeMultiItem = false
      console.log(this.lv_IsMadeMultiItem)
      if (pSelectedItem.vdoLnkUrl !== null && pSelectedItem.vdoLnkUrl !== undefined && pSelectedItem.vdoLnkUrl !== '') {
        // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
        this.fn_IncrementItemInqrFgrList()
      } else if (pSelectedItem.pdfFileYn === 'Y') {
        this.fn_IncrementItemInqrFgrList()
      } else {
        // 동영상 링크가 아닐 경우는 인쇄 실행
        this.fn_CreateToPDFContnt()
      }
//
      // FOM 파일 저장
      this.lv_SelectedFomItemList.push(pSelectedItem)

      window.console.log('@@@ 나의보관함 스크랩 단건 상세보기(    fn_OnSelectedSingleItem   ) END ... ☞ ' + new Date().getTime())
    },

    /******************************************************************************
     * Function명: fn_ReadySelectedItemsViewDetails
     * 설명:  다중 항목 선택시 PDF 보기
     * Params: N/A
     * Return: N/A
    ******************************************************************************/
    fn_ReadySelectedItemsViewDetails() {        
        console.log('@@@ 나의보관함 스크랩 다건 상세보기(    fn_ReadySelectedItemsViewDetails   ) start ... ☞ ' + new Date().getTime())        
      // 동영상 자료 단건 선택 후 '상세보기' 버튼 클릭 시는 조회수 증가 후 URL 이동
      if (this.checkCount === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '스크랩 자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else  { 
              this.selectItems = [] // 초기화
              this.lv_SelectedFomItemList = []
              this.lv_SelectedItemList = []
              this.selectItems = this.lv_BkmrkGrpCntntVOList.filter(item => item.checked)
              this.selectItems.forEach(item => {
                  // My 세일즈북의 특정 그룹에 포함된 자료 VO에 없는 untySlmtClsfNm01를 강제로 설정
                  // My 세일즈북의 자료를 상세보기 할 때, '라이브러리'라는 이름의 대 분류에 포함된 자료일 경우는 툴팁 메뉴를 비활성화 시켜주기 위해
                 item.untySlmtClsfNm01 = item.untySlmtClsfNm                 
              })
              this.lv_SelectedItemList = this.selectItems.slice(0)
       if(this.lv_SelectedItemList.length === 1) {
         this.lv_IsMadeMultiItem = false
       }

       if (this.lv_SelectedItemList.length === 1 && this.lv_SelectedItemList[0].vdoLnkUrl !== null && 
           this.lv_SelectedItemList[0].vdoLnkUrl !== '' && this.lv_SelectedItemList[0].vdoLnkUrl !== undefined) {
           this.fn_IncrementItemInqrFgrList()
       } else {
               if (this.lv_SelectedItemList.length <= SpConstants.MAX_SELECTION_ITEMS) {
                  // 동영영 자료 선택 여부 판단
                   let vodItemCount = 0
                   this.lv_SelectedItemList.forEach(item => {
                      if (item.vdoLnkUrl !== null && item.vdoLnkUrl !== '' && item.vdoLnkUrl !== undefined) {
                       vodItemCount++
                      }
                   })

                 // 동영상 자료 선택 후 상세보기 진행 시 경고 메시지 출력
                 if (vodItemCount > 0) {
                    window.fdpbridge.exec('ToastPlugin', {message: '동영상 자료는 상세보기가 불가합니다.\n동영상자료 선택 해제 후 다시 진행해 주세요..'}, () => {}, () => {})
                 } else {
                     // 아이폰 체크 로직 제외요청 (2023/05/11)
                          // if(this.$commonUtil.getIsIOS() && this.lv_SelectedItemList.length > 1) {
                          //    this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
                          //       properties: {
                          //                   single: true,
                          //                   title: "알림",
                          //                   content: '아이폰의 경우, 안내자료 상세보기는 1개씩 가능합니다.',
                          //                    title_pos_btn: "확인"
                          //                },
                          //       listeners: {
                          //                 onPopupConfirm: () => {
                          //                    console.log('onPopupConfirm call')
                          //                    this.$bottomModal.close(this.alertPop)
                          //                 },
                          //         onPopupCancel: () => {
                          //             console.log('onPopupCancel call')
                          //             this.$bottomModal.close(this.alertPop)
                          //         },
                          //         onPopupClose: () => {
                          //             console.log('onPopupClose call')
                          //             this.$bottomModal.close(this.alertPop)
                          //         }
                          //       }
                          //    });
                          //    return
                          // }

                          // 다중 선택 후 상세보기 진행시
                          this.lv_IsMadeMultiItem = true
                          this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화

                          // let pdfFileCount = 0
                          this.lv_SelectedItemList.forEach(item => {
                                if (item.pdfFileYn === 'Y') {
                                 // pdfFileCount++
                                } else {
                                  this.lv_SelectedFomItemList.push(item) // FOM 파일 저장
                                }
                          })

                         // if (pdfFileCount > 0 && pdfFileCount !== this.lv_SelectedItemList.length) { // FOM 파일과 PDF 파일이 동시에 선택되었을 경우
                         // } else if (pdfFileCount > 0 && pdfFileCount !== this.lv_SelectedItemList.length) { // 선택된 파일이 PDF 파일만 있을 경우는 인터페이스를 호출하지 않고 바로 Viewer 호출
                         // } else if (pdfFileCount === 0 && this.lv_SelectedItemList.length > 0) { // 선택된 파일들 중 PDF 파일 형식이 아닐 경우에만..FOM 파일만 선택했을때
                         // FOM 파일과 PDF 파일 동시 선택 시 모두 상세보기 및 출력, SMS, 이메일 발송 가능

                       if (this.lv_SelectedFomItemList.length > 0) {
                               this.fn_CreateToPDFContnt()
                       } else {
                                this.fn_IncrementItemInqrFgrList()
                       }

                  }
               } else {
               }
       }
      }
       console.log('@@@ 나의보관함 스크랩 다건 상세보기  end(    fn_ReadySelectedItemsViewDetails   ) End ... ☞ ' + new Date().getTime())  
    },
    /******************************************************************************
    * Function명 : fn_IncrementItemInqrFgrList
    * 설명       : 조회 이력 저장
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_IncrementItemInqrFgrList() {
      let lv_vm = this
      const trnstId = 'txTSSSA01P2'
      const auth = 'S'

      let sParams = {}
      // 컨설턴트 번호
      sParams.cnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId
      if (lv_vm.lv_IsMadeMultiItem) {
        sParams.sACntntBasInfoSVO = lv_vm.lv_SelectedItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        sParams.sACntntBasInfoSVO = tempVOList
      }

      this.post(lv_vm, sParams, trnstId, auth)
        .then(function (response) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
          lv_vm.getStore('progress').getters.getState.isShow = true
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {

              if (response.body.sASelCntntDtlSVO != null && response.body.sASelCntntDtlSVO.length > 0) {
                if (response.body.sASelCntntDtlSVO.length === sParams.sACntntBasInfoSVO.length) {
                  // 선택 파일이 단일 파일이면서, 동영상 파일일 경우는 외부 링크 연결, 그렇지 않을 경우는 PDF Viewer 실행
                  if (!lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== null && 
                       lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== undefined && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== '') { // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
                        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                           lv_vm.getStore('progress').getters.getState.isShow = false
                           window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedSingleItem.vdoLnkUrl}, () => {}, () => {})        
                  } else if (lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedItemList.length === 1 && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== null && 
                             lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== undefined && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== '') {
                              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                              lv_vm.getStore('progress').getters.getState.isShow = false
                              window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedItemList[0].vdoLnkUrl}, () => {}, () => {})
                  } else {
                              lv_vm.fn_SaveFOMFile()
                  }
                }
              }
            } else {
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
              lv_vm.getStore('progress').getters.getState.isShow = false
              if (response.msgComm.msgCd !== null && response.msgComm.msgCd.length > 0) {
                lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CreateToPDFContnt
    * 설명       : 컨설턴트 정보 조회(전문 생성을 위해)
    * 인자       : lv_UserInfo 사용자 정보 데이터 (Object)
    * Return     : void
    ******************************************************************************/
    fn_CreateToPDFContnt() {
      let lv_vm = this

      lv_vm.lv_SADCFmlmtSVOList = []
      lv_vm.lv_CompletedPDFFileVOList = []

      let pParams = {}
      pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId

      if (lv_vm.lv_IsMadeMultiItem) {        
        pParams.sACntntBasInfoSVO = lv_vm.lv_SelectedFomItemList     // FOM 파일만 PDF 생성 대상
      } else {
              let tempVOList = []
              tempVOList.push(lv_vm.lv_SelectedSingleItem)
              pParams.sACntntBasInfoSVO = tempVOList
      }
      const trnstId = 'txTSSSA22P3'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '' && response.body.sADCFmlmtSVO != null && response.body.sADCFmlmtSVO.length > 0) {
                // 서비스 호출 결과(body)가 있을 경우
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                //lv_vm.getStore('progress').getters.getState.isShow = true
                lv_vm.lv_SADCFmlmtSVOList = response.body.sADCFmlmtSVO
                lv_vm.lv_CheckDocsRetryNumberOfTimes = 0 // PDF 생성여부 확인 조회 회수 초기화
  
                lv_vm.fn_CheckContentsTransformation(lv_vm.lv_SADCFmlmtSVOList)
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                    //lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                      //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                      lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                   //lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                     //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                     lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CheckContentsTransformation
    * 설명       : FOM 파일을 PDF 파일로 변환 요청 후 요청한 PDF 생성 여부 확인
    * Params     : 변환 요청한 결과 VO 목록
    * Return     : N/A
    ******************************************************************************/
    fn_CheckContentsTransformation(pSADCFmlmtSVO) {
      let lv_vm = this
      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        sADCFmlmtSVO: pSADCFmlmtSVO
      }
      const trnstId = 'txTSSSA22P4'
      const auth = 'S'

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {
              // 서비스 호출 결과(body)가 있을 경우
              // 생성여부 확인 시도 회수를 증가
              // 20번까지만 생성확인 여부 시도
              lv_vm.lv_CheckDocsRetryNumberOfTimes++

              // FOM 파일 목록에 대한 생성여부를 결과 VO로 리턴
              // VO로 리턴된 FOM 파일 이외의 FOM파일은 계속 생성 여부를 확인
              if (response.body.sADCDoctTrfoBasSVO !== null && response.body.sADCDoctTrfoBasSVO.length > 0) {
                let tempPSADCFmlmtSVO = []
                let resultSADCDoctTrfoBasSVO = response.body.sADCDoctTrfoBasSVO.slice()

                // 생성요청 VO 목록에서
                lv_vm.lv_SADCFmlmtSVOList.forEach(dcItem => {
                  let tempFlag = false
                  let tempVO = {}

                  // 생성여부 확인된 VO 목록의 결과를 비교하여 일치하는 VO가 있을 경우
                  resultSADCDoctTrfoBasSVO.forEach(item => {
                    if (dcItem.srcFileNm01 === item.eltrnDoctId) {
                      tempFlag = true
                      tempVO = item
                    }
                  })

                  // 생성여부 확인 VO를 생성여부 확인된 VO만 별도로 저장하고 있는 VO에 저장
                  if (tempFlag) {
                    if (lv_vm.lv_CompletedPDFFileVOList !== null) {
                      let addFlag = true
                      lv_vm.lv_CompletedPDFFileVOList.forEach(item => {
                        if (tempVO.eltrnDoctId === item.eltrnDoctId) {
                          addFlag = false
                        }
                      })
                      if (addFlag) {
                        lv_vm.lv_CompletedPDFFileVOList.push(tempVO)
                      }
                    }
                  } else {
                    tempPSADCFmlmtSVO.push(dcItem)
                  }
                })

                // 생성여부 확인이 끝난 FOM 파일만 저장하고 있는 Array와 생성요청 FOM 파일 정보를 담고 있는 VO 목록이 일치하지 않으면
                // 생성여부 확인 작업을 계속 진행
                if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                  // 20번 시도 회수보다 작으면 생성여부 확인 작업을 계속 시도
                  if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                    setTimeout(function () {
                      lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                    }, 1000)
                  } else { // 그렇지 않으면 시도 중지
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                    l//v_Vm.getStore('progress').getters.getState.isShow = false
                    // 조회이력 증가 호출
                    lv_vm.$addSnackbar('PDF 변환에 실패하였습니다.')
                  }
                } else { // 그렇지 않으면 조회수 증가 단계로 이동
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  //lv_vm.getStore('progress').getters.getState.isShow = false
                  // 조회이력 증가 호출
                  lv_vm.fn_IncrementItemInqrFgrList()
                }
              } else if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                lv_vm.getStore('progress').getters.getState.isShow = false
                // 조회이력 증가 호출
                lv_vm.$addSnackbar('PDF 변환에 실패하였습니다.')
              }
            } else { // 서비스 호출 결과는 없지만
              // 변환 완료된 PDF 목록과 변환 요청 PDF 목록 개수가 일치하지 않을 경우 변화여부 확인 요청 시도
              if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          //lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_BtnESaveFile
    * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    async fn_SaveFOMFile () {

      if (process.env.NODE_ENV === 'local') {
        let viewerTargetFileList = ''
        let savePdfTargetItemList = []
        if (this.lv_IsMadeMultiItem) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.eltrnDoctFileNm = 'test.pdf'
            itemObj.localFileNm = 'test.pdf'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

            savePdfTargetItemList.push(itemObj)
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          let itemObj = {}
          itemObj.eltrnDoctFilePathNm = selectedUntySlmtItem.pdfApndFilePathNm
          itemObj.eltrnDoctFileNm = 'test.pdf'
          itemObj.localFileNm = 'test.pdf'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

          savePdfTargetItemList.push(itemObj)
        }
        savePdfTargetItemList.forEach(item => {
          try {

            viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })

        this.fn_CallPdfViewer(viewerTargetFileList)
      } else {
        let successCount = 0
        let tryCount = 0
        let savePdfTargetItemList = []
        let viewerTargetFileList = ''

        if (this.lv_IsMadeMultiItem === true) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            if (selectedUntySlmtItem.pdfFileYn === 'Y') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
              itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

              savePdfTargetItemList.push(itemObj)
            } else {
              let tempDcFmlmtSVO = null
              let tempCompletedPdfSVO = null
              this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
                if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                  tempDcFmlmtSVO = dcFmlmtSVO
                }
              })

              if (tempDcFmlmtSVO !== null) {
                this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                  if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                    tempCompletedPdfSVO = completedPDFFileSVO
                  }
                })
              }

              if (tempCompletedPdfSVO !== null) {
                let itemObj = {}
                itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
                itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

                // FOM 파일을 PDF로 변환했을 경우는 .pdf 확장자를 붙여서 파일을 다운받도록 설정
                let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
                if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                } else {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
                }

                savePdfTargetItemList.push(itemObj)
              }
            }
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          if (selectedUntySlmtItem.pdfFileYn === 'Y') {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
            itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
            itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

            savePdfTargetItemList.push(itemObj)
          } else {
            let tempDcFmlmtSVO = null
            let tempCompletedPdfSVO = null
            this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
              if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                tempDcFmlmtSVO = dcFmlmtSVO
              }
            })

            if (tempDcFmlmtSVO !== null) {
              this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                  tempCompletedPdfSVO = completedPDFFileSVO
                }
              })
            }

            if (tempCompletedPdfSVO !== null) {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
              if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
              } else {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
              }

              savePdfTargetItemList.push(itemObj)
            }
          }
        }

        // savePdfTargetItemList.forEach(item => {
        //   if (process.env.NODE_ENV === 'development') {
        //     item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
        //     item.eltrnDoctFileNm = 'test.pdf'
        //     item.localFileNm = 'test.pdf'
        //     item.fileTitle = '[TEST]'
        //   }
          
        //   try {
        //     this.fn_FdpSaveFile(item)
        //       .then(response => {
        //         successCount++
        //         tryCount++
                
        //         viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
        //         if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
        //           if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
        //             this.fn_CallPdfViewer(viewerTargetFileList)
        //           } else if (tryCount === this.lv_SelectedItemList.length) {
        //             // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        //             //this.getStore('progress').getters.getState.isShow = false
        //             this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
        //           }
        //         } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
        //           this.fn_CallPdfViewer(viewerTargetFileList)
        //         }
        //       }).catch(error => {
        //         tryCount++
        //         // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
        //         // 다중 선택일 경우
        //         if (this.lv_IsMadeMultiItem) {
        //           // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
        //           if (tryCount === this.lv_SelectedItemList.length) {
        //           // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        //             this.getStore('progress').getters.getState.isShow = false
        //             // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
        //             this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
        //           }

        //           if (SpConstants.DEBUG_MODE_CON) window.console.log('@@@ 나의보관함 스크랩 다건 상세보기( 다건 실패 : ' + new Error(error))
        //         } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
        //           // 화면 깜박거림을 방지하기 위한 로딩바를 해제
        //           this.getStore('progress').getters.getState.isShow = false
        //           // 오류 팝업 메시지 출력
        //           this.$addSnackbar('총 1건 중 1건 저장이 실패하였습니다')
        //         }
        //         if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
        //       })
        //   } catch (error) {
        //     if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
        //   }
        // })

        for(let i=0; i<savePdfTargetItemList.length; i++){
          try {
            if (process.env.NODE_ENV === 'development') {
              item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              item.eltrnDoctFileNm = 'test.pdf'
              item.localFileNm = 'test.pdf'
              item.fileTitle = '[TEST]'
            }

            await this.fn_FdpSaveFile(savePdfTargetItemList[i]).then(response => {
              successCount++
              tryCount++
              console.log('fn_FdpSaveFile  test-2 >' , successCount ,tryCount, savePdfTargetItemList[i] )
              viewerTargetFileList += response + 'tssB' + savePdfTargetItemList[i].fileTitle + 'tssA'
              if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
              console.log('fn_FdpSaveFile  test-3 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
                  this.fn_CallPdfViewer(viewerTargetFileList)
                } else if (tryCount === this.lv_SelectedItemList.length) {
                  console.log('fn_FdpSaveFile  test-4 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                  //this.getStore('progress').getters.getState.isShow = false
                  this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                }
              } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
                  console.log('fn_FdpSaveFile  test-10 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                this.fn_CallPdfViewer(viewerTargetFileList)
              }
            }).catch(error => {
              tryCount++
              // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
              // 다중 선택일 경우
              if (this.lv_IsMadeMultiItem) {
                                console.log('fn_FdpSaveFile  test-5 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
                if (tryCount === this.lv_SelectedItemList.length) {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                  this.getStore('progress').getters.getState.isShow = false
                  // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
                  this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                }

                if (SpConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSP201M ■■■■■ 다건 실패 : ' + new Error(error))
              } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
                // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                this.getStore('progress').getters.getState.isShow = false
                // 오류 팝업 메시지 출력
                this.$addSnackbar('총 1건 중 1건 저장이 실패하였습니다')
              }
              if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
            })
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
         }

      }
    },
    
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명       : 첨부파일을 저장한다.
    ******************************************************************************/
    fn_FdpSaveFile(obj) {
      return new Promise((resolve, reject) => {
        // .pdf 확장자가 없을 경우 확장자 첨부
        if (obj.localFileNm.indexOf('.pdf') < 0) {
          obj.localFileNm = obj.localFileNm + '.pdf'
        }

        
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.eltrnDoctFilePathNm,
          filename: obj.eltrnDoctFileNm, // src Directory + fileName
          type: 'pdf',
          localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
          localfilename: obj.localFileNm, // local fileName
          rename: 'N'
        }, (result) => {
          resolve(result.data)
        }, (result) => {
          reject(result.data)
        })
      })
    },

    fn_CallPdfViewer(viewerTargetFileList) {
      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
      this.getStore('progress').getters.getState.isShow = false
      let libraryItemCount = 0
      if (this.lv_IsMadeMultiItem) {
        this.lv_SelectedItemList.forEach(item => {
          if (item.untySlmtClsfNm01.indexOf('SpConstants.LIBRARY_CATE_NM') > -1 || item.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
            libraryItemCount++
          }
        })
      } else {
        if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
          libraryItemCount++
        }
      }


      // 대분류명이 "라이브러리" 형태가 아닌 "(p)" 등이 끝에 붙어올수 있다. 이를 고려함.
      // lv_SelcectedClsfSytmNm 변수는 swipe 탭에서 "라이브러리" or "사랑On도움방" 탭을 선택후 pdf 뷰 보기를 할경우임.
      let menuAuth = ''
      if (libraryItemCount > 0 || this.lv_SelcectedClsfSytmNm.indexOf('라이브러리') !== -1 || this.lv_SelcectedClsfSytmNm.indexOf('사랑On도움방') !== -1) { // 테블릿과 별도 구현, 동기화시 주의!!!!!
       menuAuth = '0000' // 안드로이드 pdf 뷰에서 "SMS" 메뉴 숨기기(다중 선택시 "라이브러리" or "사랑On도움방" 포함되면 선택된 모든 파일에 대해서도 보이지 않음)
      } else {
       menuAuth = '1111'
      }

      let lv_vm = this
      // PDF 뷰어 호출
      window.fdpbridge.exec('callPdfViewerPlugin', {
        path: '/sli/tss/comm/fmlmt',
        menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
        file: viewerTargetFileList // 서버에 올릴때.
        // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
      }, function (result) {
        // 성공콜백
        // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
        if (result.data === 'btn1') {
          //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
          // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
          lv_vm.fn_SMSBtnClicked()
        }
      }, function (result) {
        // 실패콜백
        if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
      })
    },
    /*********************************************************
    * Function명: fn_SMSBtnClicked
    * 설명: TSSSP201M.TAB에서 "문자전송" 버튼 클릭시
    * Params:  N/A
    * Return: N/A
    *********************************************************/
    fn_SMSBtnClicked() {
     if (this.checkCount > 5) {
         this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
         return
     }

     if (this.checkCount === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '스크랩 자료를 선택하여 주십시요.')
     } else {
       let lv_vm = this
       // 파라미터 설정
       let pParams = {}

       const trnstId = 'txTSSSA01S8'
       const auth = 'S'
       this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.startDt !== undefined && response.body.startDt !== '') {
                if (new Date() >= new Date(response.body.startDt) && new Date() <= new Date(response.body.endDt)) {
                  //알럿띄워야됨 종료시키고 pk.son
                  lv_vm.$addSnackbar(response.body.msg)
                } else {
                  lv_vm.fn_OpenMSPCM152P()
                }
              }else{
                // 서버 config 키값이 정의 되어 있지 않더라도 SMS 고객선택 팝업 호출
                lv_vm.fn_OpenMSPCM152P()
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
     }  
    },

   /*********************************************************
     * Function명: fn_OpenMSPSA210P
     * 설명:  스크랩 팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA210P() {
            console.log("MSPSA209P-전체선택--> fn_OpenMSPSA210P  start ")

      if (this.checkCount === 0) { 
        // this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '스크랩 자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_BkmrkGrpCntntVOList.filter(item => item.checked)
        this.lv_SelectedUntySlmtItems = this.selectItems.slice()

        let lv_vm = this
        let itemList = []
        this.lv_Popup210 = this.$bottomModal.open(MSPSA210P,{
        properties: {
          pPage: 'MSPSA209P',
          pSelectedUntySlmtItems: this.lv_SelectedUntySlmtItems // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            popupCloseMSPSA210P: (pData) => {
                if(pData !== ''){              
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }else{
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }
            }
         }
        })
      } 
    }, 
    /*********************************************************
     * Function명: fn_OpenMSPSA152P
     * 설명:  고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A 
     *********************************************************/
    fn_OpenMSPCM152P() {
      let lv_vm = this
      this.custListObj = []

     lv_vm.lv_Popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
     properties: {
       pChnlCustIds: [
         {targetType: 's',
          parentsViewId: 'MSPSA209P',
          custList: []
         }
       ]
     },
     listeners: {
        onPopupClose: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
      },
      onNextService: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
        lv_vm.custListObj = [{targetType: 's', parentsViewId: 'MSPSA209P', custList: pData.selectList}]    
        lv_vm.fn_OpenMSPCM128P()
      },
    }
  })          

  },
    /*********************************************************
    * Function명: fn_OpenMSPCM128P
    * 설명: 수신고객목록 팝업오픈 (문자발송)
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenMSPCM128P: _.debounce(function () {
      let lv_vm = this
      let guidList = []
      if(lv_vm.lv_SelectedUntySlmtItems.length > 1){
        guidList = lv_vm.lv_SelectedUntySlmtItems        
      }else{
        guidList.push(lv_vm.lv_SelectedUntySlmtItems)
      }
      
        console.log('lv_vm.custListObj >> ', lv_vm.custListObj)
        console.log('lv_vm.guidList >> ',  guidList)
        lv_vm.lv_Popup128 = lv_vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: lv_vm.custListObj, // param셋팅
          pSelectedUntySlmtItemList: guidList , 
          pIsShowTSSSP128P: true
        },
        listeners: {
            // 팝업 닫히면서 실행되는 함수
           onPopupClose: (pData) => {
             // pData.rtnData = {smsTargetCustList, 'confirm', parentsViewId, mth}
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },
             // 단순 팝업 종료
           onPopupCancel: () => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },         
      // 터치온 발송 프로세스 async
           onNextService: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
              lv_vm.custListObj = []
              lv_vm.custListObj = pData.rtnData
             // 터치온 팝업 오픈(MSPCM154P)
              lv_vm.fn_OpenMSPCM154P()
           }
        }
      })    
    }, 300),    

/*********************************************************
 * Function명: fn_OpenMSPCM154P
 * 설명: 터치On 문자발송 팝업 오픈
 * 
 *********************************************************/
 fn_OpenMSPCM154P: _.debounce(function () {

  let lv_vm = this

  lv_vm.lv_Popup154 = lv_vm.$bottomModal.open(MSPCM154P, {
    properties: {
      pPage: lv_vm.custListObj.parentsViewId,                   // 모화면 페이지명
      pSmsTargetCustList: lv_vm.custListObj.smsTargetCustList,  // 수신고객리스트
      pMsg: lv_vm.custListObj.msg,                              // 선택된 상용구
      pIsNotEditable: lv_vm.custListObj.isNotEditable           // 메시지 수정가능여부
    },
    listeners: {
      onPopupClose: (pData) => { 
        if (pData.str === 'confirm') {
          lv_vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
        }
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup154)
      }
    }
  })
  } , 300),      


  /*********************************************************
   * Function명: fn_OpenPopEmailCustList
   * 설명:  고객선택 수신 팝업오픈
   * Params: N/A
   * Return: N/A 
   *********************************************************/
    fn_OpenPopEmailCustList() {

     if (this.checkCount > 5) {
         this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
         return
     }

     if (this.checkCount === 0) { 
         // this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', '스크랩 자료를 선택하여 주십시요.')
     } else {      
     
        let lv_vm = this
        this.custListObj =  [
           {targetType: 'e',
            parentsViewId: 'MSPSA209P',
            custList: []
           }
         ]
        let selectPDFfile = []

        lv_vm.lv_popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
        properties: {
          pChnlCustIds: lv_vm.custListObj,
          pPDFfile : selectPDFfile
        },
        listeners: {
          onPopupClose: (pData) => {
            // 모달 닫기
            lv_vm.$bottomModal.close(lv_vm.lv_popup152)
          },
          onNextService: async (pData, selectPDFfile) => {
           // 중복제거
            let custList = []    // 문자수신 고객 list
            let cnt = 0
  
            if (pData.selectList.length > 0) {
              for (let i = 0; i < pData.selectList.length; i++) { 
                cnt = 0
                if (i === 0) {
                  custList.push(pData.selectList[i])
                } else {
                  for (let j = 0; j < custList.length; j++) {
                    if (pData.selectList[i].chnlCustId === custList[j].chnlCustId) {
                      cnt++
                    }
                  }
                  if (cnt === 0) {
                    custList.push(pData.selectList[i])
                  }
                }
              }
            }

            lv_vm.custListObj = [{targetType: 'e', parentsViewId: 'MSPSA209P', custList: custList}]  
            selectPDFfile = lv_vm.lv_SelectedUntySlmtItems
            let lv_pTitle = '삼성생명' 
            let lv_pText = '고객님 안녕하세요.\n' +
                            '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + '입니다.\n' +
                            '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                            '오늘도 즐거운 하루 되세요!'  
            let lv_pDataModify = true                
            if (lv_vm.custListObj[0].custList.length > 0) {
              // 모달 닫기
             await lv_vm.$bottomModal.close(lv_vm.lv_popup152)
              // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
              await lv_vm.$cmUtil.fn_OpenMSPCM191P(lv_vm, lv_vm.custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify)
            }
          },
        }
       })
      } 
    },      
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked
     * 설명: 인쇄
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzConfirmBtnClicked() {
      if (this.checkCount === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '스크랩 자료를 선택하여 주십시요.')
      } else  { 
              this.pIsSelectedMultiItem = false
              this.selectItems = [] // 초기화
              this.lv_SelectedItemList = [] // 초기화
              this.selectItems = this.lv_BkmrkGrpCntntVOList.filter(item => item.checked)
              this.selectItems.forEach(item => {
                  // My 세일즈북의 특정 그룹에 포함된 자료 VO에 없는 untySlmtClsfNm01를 강제로 설정
                  // My 세일즈북의 자료를 상세보기 할 때, '라이브러리'라는 이름의 대 분류에 포함된 자료일 경우는 툴팁 메뉴를 비활성화 시켜주기 위해
                 item.untySlmtClsfNm01 = item.untySlmtClsfNm                 
              })
              this.lv_SelectedItemList = this.selectItems.slice(0)              
              if(this.lv_SelectedItemList.length > 1) {
                 this.pIsSelectedMultiItem = true
               }
               this.fn_OzPrintCntntList()
      }
    },      
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked()
     * 설명: 선택 자료 목록 인쇄 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzPrintCntntList() {
      console.log('@@@ 나의보관함 스크랩 인쇄  fn_OzPrintCntntList START...☞ ' + new Date().getTime())
      let lv_Vm = this

      let tempUntySlmtItemList = []
      // 다중 선택일 경우 선택 자료 목록을 파라미터로 설정
      // if (lv_Vm.pIsSelectedMultiItem) {
      //   tempUntySlmtItemList = lv_Vm.lv_SelectedItemList
      // } else { // 단일 선택일 경우 선택 자료를 파라미터로 설정
      //   tempUntySlmtItemList.push(lv_Vm.lv_SelectedItemList)
      // }
      tempUntySlmtItemList = lv_Vm.lv_SelectedItemList

      // 로컬 테스트용 코드
      if (process.env.NODE_ENV === 'local') {
        tempUntySlmtItemList.forEach(item => {
          item.pdfApndFileStoreNm = 'test_local.pdf'
          item.pdfApndFilePathNm = ''
        })
      }

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        pblNos: lv_Vm.lv_SpinValue,                 // 기본 1 매 설정 
        sACntntBasInfoSVO: tempUntySlmtItemList,
        cnsltInfoIcluYN: this.lv_IsIncludeCnsltInfo  // 컨설턴트 개인정보 이미지 미 포함으로 설정됨.
      }

      const trnstId = 'txTSSSA11P9'
      // const trnstId = 'txTSSSA11P1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( lv_Vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                  console.log('@@@ 나의보관함 스크랩 인쇄  fn_OzPrintCntntList this.post START ☞ ' + new Date().getTime())
              if (response.body.sACntntSVO.length === lv_Vm.selectItems.length) {
              // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                  lv_Vm.fn_printtostMesg()                              // 인쇄처리 완료 메세지  
                //lv_Vm.$emit('evtCloseTSSSA011P', true)
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
           console.log('@@@ 나의보관함 스크랩 인쇄  fn_OzPrintCntntList END ☞ ' + new Date().getTime())
        })
        .catch(function (error) {
          window.vue.error(error)
           console.log('@@@ 나의보관함 스크랩 인쇄  fn_OzPrintCntntList Error ☞ ' + new Date().getTime())
        })
    },      
    /******************************************************************************
    * Function명 : fn_WifiNetCheck
    * 설명       : 사내망 체크
    ******************************************************************************/
    fn_WifiNetCheck () {
      if (!this.$MenuManager.fn_GetInternalNetwork()) {
        this.$addSnackbar('사내Wifi망에서만 접속 가능합니다.')
        this.fn_Close()
      }
    },      
    /******************************************************************************
     * Function명 : fn_xxxx
     * 설명       : 버텀 컨트롤 
     ******************************************************************************/

    fn_ontostMesg(){
        //this.$refs.stackableSnackbar.show({text:'삭제되었습니다.'});
        this.getStore('toast').dispatch('ADD', '삭제되었습니다.')
        this.fn_deleteConfirm_close()
    }, 
    fn_printtostMesg(){
        //this.$refs.stackableSnackbar.show({text:'인쇄되었습니다.'});
        this.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
        this.fn_print_BottomSheet_close()
    }, 
    fn_SmstostMesg(){
        //this.$refs.stackableSnackbar.show({text:'발송되었습니다.'});
        this.getStore('toast').dispatch('ADD', '발송되었습니다.')
    },     
    fn_BottomSheet_open() { 
      this.$refs.bottomSheetPSCM_209P.open();
    },
    fn_BottomSheet_close() { 
      this.$refs.bottomSheetPSCM_209P.close();
    },
    fn_deleteConfirm_open() { 
     if (this.checkCount === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '스크랩 자료를 선택하여 주십시요.')
         this.fn_BottomSheet_open()
     } else {
      this.fn_BottomSheet_close()
      this.$refs.delete_nsbottomsheet1.open();  
     }
    },
    fn_deleteConfirm_close() { 
      this.$refs.delete_nsbottomsheet1.close(); 
     //2 this.fn_BottomSheet_open()
     this.fn_CheckSelectedItemCount()
    },
    fn_print_BottomSheet_open() { 
      if (this.checkCount === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '인쇄할 스크랩 자료를 선택하여 주십시요.')
      } else {
          this.fn_BottomSheet_close()
          this.$refs.print_bottomSheet.open();
      }    
    },
    fn_print_BottomSheet_close() { 
      this.$refs.print_bottomSheet.close();
      this.fn_BottomSheet_open()
    },

     fn_HistoryBack () {
        // 체크박스가 있는 경우
        console.log('@@@ back key >>' , this.lv_SelectedUntySlmtItems)

        if (this.lv_SelectedUntySlmtItems.length > 0 ) {
                let alertMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'

                let alertObj = this.$bottomModal.open(MSPBottomAlert, {
                  properties: {
                     type: 'B',
                     content: alertMsg,
                     title_pos_btn: "예",
                     title_neg_btn: "아니오"
                  },
                  listeners: {
                    onPopupConfirm: () => {
                     this.$bottomModal.close(alertObj)                
                      // 특정화면 이동 혹은 이전 화면 이동
                      console.log('back_2')
                      this.$emit('onPopupClose')
                   },
                    onPopupClose: () => {
                     this.$bottomModal.close(alertObj)
                    }
                 }
               })
        } else {
          console.log('back_1')
            //this.$router.go(-1) // 이전화면으로 이동
            this.$emit('onPopupClose')
         }
      }          
  }  
};
</script>

<style scoped>
</style>